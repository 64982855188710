<template>
	<div>
		<div class="md-booking-toolbar" style="display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-start; align-items:  center; align-content: center; height: 50px; background-color: #fff" >
			<div class="" style="width: 40px; text-align: center" >
				<a @click=" $router.go(-1)" style="padding: 10px 10px 10px 2px" >
					<md-icon>arrow_back</md-icon>
				</a>
			</div>
			<h3>
				{{lang.bloquear_habitacion.toUpperCase()}}
			</h3>
		</div>
		<md-dialog-alert :md-active.sync="alert.show" :md-content="alert.text"  :md-confirm-text="lang.cerrar" />
		<md-dialog-confirm class="accept" :md-active.sync="alert.show2" :md-content="alert.text" md-cancel-text=""  md-confirm-text="OK"  @md-confirm="onConfirm" />

		<div class="md-content createlock">
			<div v-show="this.$store.getters.compartidas.categorias.length > 0">
			<md-field  :class="{'md-invalid': form_error.tipo}">
					<md-icon>workspaces</md-icon>
					<label>{{lang.tipo}}</label>
					<md-select v-model="selected.tipo" md-dense >
						<md-option value="" key="t1">{{lang.seleccione}}...</md-option>
						<md-option v-for="(tipo, index) in tipos" :value="index.toString()" :key="tipo.id">{{tipo.nombre}}</md-option>
					</md-select>
					<span class="md-error">{{lang.requerido}}</span>
				</md-field>
			</div>
			<div >
				<md-field  :class="{'md-invalid': form_error.categoria}">
					<md-icon>label</md-icon>
					<label >{{lang.categoria}}</label>
					<md-select v-model="selected.categoria" md-dense >
						<md-option value="">{{lang.seleccione}}...</md-option>
						<md-option v-for="(categoria, index) in categorias" :value="index.toString()" :key="categoria.id_categoria">{{categoria.nombre}}</md-option>
					</md-select>
					<span class="md-error">{{lang.requerido}}</span>
				</md-field>
			</div>
			<div>
				<md-field  :class="{'md-invalid': form_error.cuarto}">
					<md-icon>bed</md-icon>
					<label >{{lang.habitacion}}</label>
					<md-select v-model="selected.cuarto"  md-dense>
						<md-option value="">{{lang.seleccione}}...</md-option>
						<md-option v-for="(cuarto, index) in cuartos" :value="index.toString()" :key=" cuarto.id_cuarto">{{cuarto.nombre}}</md-option>
					</md-select>
					<span class="md-error">{{lang.requerido}}</span>
				</md-field>
			</div>

			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<div>
						<md-datepicker v-model="selected.ingreso" :class="{'md-invalid': form_error.ingreso}">
							<label>{{lang.desde}}</label>
							<span class="md-error">{{lang.requerido}}</span>
						</md-datepicker>
					</div>
				</div>

				<div class="md-layout-item">
					<md-datepicker v-model="selected.salida" :class="{'md-invalid': form_error.salida}">
						<label>{{lang.hasta}}</label>
						<span class="md-error">{{lang.requerido}}</span>
					</md-datepicker>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item md-size-100 sub-head">
					<md-switch v-model="selected.eliminacion_auto.show" class="md-primary">{{lang.delete_auto}} </md-switch>
				</div>

				<div class="md-layout-item md-size-40" v-if="selected.eliminacion_auto.show">
					<md-field>
						<md-icon>timer_off</md-icon>
						<md-input type="number" name="tiempo" id="tiempo" v-model="selected.eliminacion_auto.tiempo" :disabled="sending" />
					</md-field>
				</div>
				<div class="md-layout-item  md-size-60" v-if="selected.eliminacion_auto.show">
					<md-field >
						<md-select v-model="selected.eliminacion_auto.formato"  md-dense>
							<md-option value="">{{lang.seleccione}} </md-option>
							<md-option value="minutes">{{lang.minutos}} </md-option>
							<md-option value="hours">{{lang.horas}} </md-option>
							<md-option value="days">{{lang.dias}} </md-option>
						</md-select>
					</md-field>
				</div>
				<div class="md-layout-item md-size-100 alert" v-if="details.fecha_eliminacion != ''">
					{{lang.delete_msg}} {{details.fecha_eliminacion}}
				</div>

				<div class="md-layout-item md-size-100">
					<md-field>
						<label>{{lang.notas}}</label>
						<md-textarea v-model="selected.notas"></md-textarea>
					</md-field>
				</div>

			</div>
			<div v-if="details.noches"  class="md-layout-item" style="border: 1px dashed  dimgrey; font-size: 16px; display: flex; align-items: center; justify-content: center; height: 40px; margin-bottom: 1em">
				<div class = "text-center" style=" display: flex; align-items: center; ">
					<md-icon class = "md-large">night_shelter</md-icon> {{details.noches}}<span style="margin-left: 7px"> {{lang.noches}}</span>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class = "md-layout-item">
					<md-button class="md-raised secondary"@click="$router.go(-1)"> <md-icon>keyboard_arrow_left</md-icon>{{lang.cancelar}}</md-button>
				</div>
				<div class="md-layout-item" style="text-align: right">
					<md-button class="md-raised md-primary" @click="setDone('first')">{{lang.guardar}} <md-icon>check</md-icon></md-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {empty, request, avatar, Utility} from '../../../helpers';
	import moment from 'moment';
	export default {
		name: 'CalendarioCreateBloqueo',
		data: () => ({
			alert: {
				show : false,
				text : 'test',
				show2: false
			},
			active: 'first',
			first: false,
			sending: false,
			tarifas_x_dia: {},
			form_error :{
				tipo : false,
				categoria:false,
				cuarto : false,
				ingreso: false,
				salida : false,
				tiempo : false,
				formato : false,
			},
			selected : {
				ingreso : null,
				salida  : null,
				tipo: '',
				categoria: '',
				cuarto  : '',
				noches  : 0,
				eliminacion_auto:  {
					show : false,
					tiempo : '',
					formato : ''
				},
				notas : '',
			}
		}),
		computed: {

			lang() {return this.$store.state.lang},
			tipos(){

				if(!empty(this.$store.getters.habitaciones)) {

					if(this.$store.getters.compartidas.categorias.length === 0){
						let indexTipo = this.$store.getters.habitaciones.findIndex((h) =>  h.tipo == "privada" );
						this.selected.tipo = indexTipo.toString();
					}

					if(this.$store.state.create_data.tipo != '') {
						this.selected.tipo = this.$store.state.create_data.tipo;
						this.$store.state.create_data.tipo = '';
					}
					return this.$store.getters.habitaciones.map(t => ({id: t.id_tipo_habitacion, nombre: t.tipo}))
				}

				return  [];
			},
			categorias(){

				if(this.$store.state.create_data.categoria != '') {
					this.selected.categoria = this.$store.state.create_data.categoria;
					this.$store.state.create_data.categoria = '';
				}else{
					this.selected.categoria = '';
				}

				if(this.selected.tipo !== '') {
					return this.$store.getters.habitaciones[this.selected.tipo].categorias
				}

				return  [];
			},

			cuartos(){
				if(this.$store.state.create_data.cuarto != '') {
					this.selected.cuarto = this.$store.state.create_data.cuarto;
					this.$store.state.create_data.cuarto = '';
				}else{
					this.selected.cuarto = '';
				}

				if(this.selected.tipo !== '' && this.selected.categoria !== '') {
					return this.$store.getters.habitaciones[this.selected.tipo].categorias[this.selected.categoria].habitaciones
				}

				return  [];
			},

			details(){

				let ingreso = null;
				let salida  = null;
				let noches = null;
				let categoria = {};
				let cuarto  = {};
				let tipo  = {};
				let habitacion = '';
				let capacidad = 0;
				let tiempo = 0;
				let formato = '';
				let notas = this.selected.notas;
				let fecha_eliminacion = '';

				if(this.selected.ingreso && this.selected.salida ) {
					ingreso = moment(this.selected.ingreso);
					salida = moment(this.selected.salida);
					noches =  salida.diff(ingreso, 'days');
				}

				if(this.selected.cuarto !==''){
					categoria = this.categorias[this.selected.categoria];
					cuarto  = this.cuartos[this.selected.cuarto];
					tipo  = this.cuartos[this.selected.cuarto].tipo;
					habitacion =  `${categoria.nombre} - ${cuarto.nombre} (${tipo})`
					capacidad = categoria.capacidad_estandar;
				}

				if(this.selected.eliminacion_auto.show){
					tiempo = this.selected.eliminacion_auto.tiempo;
					formato = this.selected.eliminacion_auto.formato;
					if(!empty(tiempo) && !empty(formato)) {
						fecha_eliminacion = moment().add(tiempo, formato).format('DD/MM/YY HH:mm')
					}
				}else{
					this.selected.eliminacion_auto.formato = '';
					this.selected.eliminacion_auto.tiempo = '';
				}

				return {
					ingreso,
					salida,
					noches,
					categoria,
					cuarto,
					tipo,
					habitacion,
					notas,
					formato,
					tiempo,
					fecha_eliminacion
				}
			}
		},
		methods: {
			onConfirm(){
				this.$router.push({name:'calendario'})
			},
			async setDone (step) {
				let error  = 0;
				switch (step) {
					case 'first' :
						this.reset();

						if(this.selected.tipo == ''){
							this.form_error.tipo  = true;
							error++;
						}

						if(this.selected.categoria === ''){
							this.form_error.categoria = true;
							error++;
						}

						if(this.selected.cuarto === ''){
							this.form_error.cuarto = true;
							error++;
						}

						if(empty(this.selected.ingreso)){
							this.form_error.ingreso = true;
							error++;
						}

						if(empty(this.selected.salida)){
							this.form_error.salida  = true;
							error++;
						}

						if(this.details.noches < 1){
							this.form_error.ingreso = true;
							this.form_error.salida  = true;
							error++;

							this.alert.show = true;
							this.alert.text = this.lang.seleccione_fecha_rango;
							this.alert.btn = this.lang.cerrar;

						}

						if(error  == 0){
							let data ={
								ingreso : this.details.ingreso.format('DD/MM/YYYY'),
								salida : this.details.salida.format('DD/MM/YYYY'),
								room_id : this.details.cuarto.id_cuarto
							};


							this.$store.state.loading = true;
							let response  = await request(base_url+'/api/disponibilidad?jwt='+this.$store.state.jwt, Utility.convertModelToFormData(data));
							this.$store.state.loading = false;
							if(response.ok){
								response = response.r;
								if(typeof response.data == 'object'){
									if(response.data.status != 'ok'){
										this.alert.text = response.data.message;
										this.alert.show = true;
										return
									}

									let bloqueo = {
										"jsonBloqueosMultiples": "",
										"idHabitacion": this.details.cuarto.id_cuarto,
										"desde": this.details.ingreso.format('DD/MM/YYYY'),
										"hasta": this.details.salida.format('DD/MM/YYYY'),
										"date_bloquear":  this.details.fecha_eliminacion,
										"nota": this.details.notas
									}

									this.$store.state.loading = true;
									response  = await request(base_url+'/bloquearHabitacion?view=json&jwt='+this.$store.state.jwt, Utility.convertModelToFormData(bloqueo));
									this.$store.state.loading = false;
									if(response.ok) {
										response = response.r;
										if (typeof response.data == 'object') {
											if (response.data.status != 'ok') {
												this.alert.text = response.data.message;
												this.alert.show = true;
												return
											}

											this.alert.text = response.data.message;
											this.alert.show2 = true;

										}
									}
								}
							}
						}

						break;
				}
			},
			reset() {
				this.form_error = {
					tipo            : false,
					categoria       : false,
					cuarto          : false,
					ingreso         : false,
					salida          : false,
					formato			: false,
					tiempo			: false
				}
			}
		},
		mounted() {
			this.$store.state.loading = false;
			if(empty(this.$store.getters.habitaciones)){
				this.$router.push({name:'calendario'});
			}else{
				if(this.$store.state.create_data.date) {
					let date = this.$store.state.create_data.date.split('-');
					date = new Date(date[0], date[1] - 1, date[2]);
					this.selected.ingreso = date;
					date.setDate(date.getDate() + 1);
					this.selected.salida = date;
					this.$store.state.create_data.date = null;
				}
			}
		}
	}
</script>

<style lang="scss" >
	.md-dialog.accept{

	}
	.md-content.createlock {
		position: relative;
		height: calc(100vh - 115px);
		overflow: auto;
		padding: 30px;
		.sub-head{
			font-size: 14px;
			color: rgba(0,0,0,.6);
		}
		.md-select-value{
			padding-left: 13px;
		}

		.details {
			font-size: 14px;
			border: 1px dashed  dimgrey;
			.md-layout-item {
				padding: 7px;
				text-align: center;

				i{
					font-size: 20px;
				}
				.label {
					font-size: 11px;
					color: rgba(0, 0, 0, 0.54)
				}
			}
		}
		.tarifas{
			margin-top: 30px;
			.md-field {
				width: 100%;
				min-height: 48px;
				margin: 0px 0 11px;
				padding-top: 16px;
				display: flex;
				position: relative;
				font-family: inherit;
			}
		}

		.alert {
			text-align: center;
			margin-bottom: 10px;
			background-color: #ff5252;
			color: white;
			font-size: 14px;
			padding: 7px;
		}
	}
</style>
